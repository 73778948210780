import { UnitSpecialImage } from './unit-special-image'

class CvcResolver {
    constructor() {
        this.unitSpecialImage = new UnitSpecialImage()
    }

    initialize() {
        this.initPopupCloseModals()
        this.unitSpecialImage.init()
    }

    initPopupCloseModals() {
        window.addEventListener('click', function (event) {
            const currentTargetId = event.target.id
            const regex = new RegExp('\\-modal$', 'gm')
            regex.test(currentTargetId) ? $j(event.target).fadeOut() : ''
        })
    }

    get unitSplImage() {
        return this.unitSpecialImage
    }
}

window.cvcResolver = new CvcResolver()

window.onload = () => {
    window.cvcResolver.initialize()
}
