export class UnitSpecialImage {
    currentUnitImageIndex
    unitImages
    condnText

    init() {
        this.initUnitSpecialImage()
    }

    initUnitSpecialImage() {
        this.bindUnitSpecialImageFunctions()
    }

    bindUnitSpecialImageFunctions() {
        $j(PDPDICT.PDP_SPECIAL_IMG_MODAL.CLOSE_MODAL_ID).click(() => {
            $j(PDPDICT.PDP_SPECIAL_IMG_MODAL.MODAL_ID).fadeOut()
        })
    }

    moveToNextImage() {
        this.showSlides(this.currentUnitImageIndex + 1)
    }

    moveToPreviousImage() {
        this.showSlides(this.currentUnitImageIndex - 1)
    }

    showSlides(n) {
        let i

        this.currentUnitImageIndex =
            n > this.unitImages.length ? 1 : n < 1 ? this.unitImages.length : n
        const slides = $j('.stock-image')
        const modalSlides = $j('.stock-image.modal-stock-image')

        for (i = 0; i < slides.length; i++) {
            $j(slides[i]).hide()
            $j(modalSlides[i]).hide()
        }

        $j(slides[this.currentUnitImageIndex - 1]).show()
        $j(modalSlides[this.currentUnitImageIndex - 1]).show()
    }

    getUnitImageHtml(unitImgArr, $mainImageContainer) {
        let unitImagUnitHml = `
          <div class="stock-image-overlay">
              <img src="/skin/frontend/cru/default/images/pdp/base/eye_icon.svg">
              <div class="overlay-text">Click to view</div>
          </div>`

        unitImgArr.forEach(unitImage => {
            unitImagUnitHml += `<div class='stock-image'><img src='${unitImage}' style="height:${$mainImageContainer.height()}px;"/></div>`
        })

        if (unitImgArr.length > 1)
            unitImagUnitHml +=
                '<a class="prev" onclick="currentResolver.unitSplImage.moveToPreviousImage()">&#10094;</a><a class="next" onclick="currentResolver.unitSplImage.moveToNextImage()">&#10095;</a>'

        return unitImagUnitHml
    }

    getUnitImageModalHtml(unitImgArr) {
        let unitImagUnitHml = ''

        unitImagUnitHml += `<div class="ps-img-container zoom-img">`
        unitImgArr.forEach(unitImage => {
            unitImagUnitHml += `<div class='stock-image modal-stock-image'>
                                <img class="stock-img" src='${unitImage}' data-zoom-image='${unitImage}'/>
                            </div>`
        })
        unitImagUnitHml += '</div>'

        if (unitImgArr.length > 1)
            unitImagUnitHml +=
                '<a class="prev" onclick="currentResolver.unitSplImage.moveToPreviousImage()">&#10094;</a><a class="next" onclick="currentResolver.unitSplImage.moveToNextImage()">&#10095;</a>'

        return unitImagUnitHml
    }

    getUnitImageArr(iconInfo) {
        const $imageArr =
            iconInfo.unit_image_arr && iconInfo.unit_image_arr.length > 0
                ? iconInfo.unit_image_arr
                : iconInfo.unit_lx_image_arr && iconInfo.unit_lx_image_arr.length > 0
                ? iconInfo.unit_lx_image_arr
                : []
        this.condnText = iconInfo.unit_condition_text ?? ''
        this.unitImages = $imageArr
        return $imageArr
    }

    populateModalUnitImages(iconInfo) {
        const $specialImgModal = $j(PDPDICT.PDP_SPECIAL_IMG_MODAL.MODAL_ID)
        const $specialImage = $specialImgModal.find(PDPDICT.PDP_SPECIAL_IMG_MODAL.SPL_IMG_CONTAINER)
        $specialImgModal
            .find(PDPDICT.PDP_SPECIAL_IMG_MODAL.SPL_IMG_CONDN)
            .text(iconInfo.unit_condition_text)

        const $unitImageArr = this.getUnitImageArr(iconInfo)

        if ($unitImageArr.length > 0) {
            $specialImage.html(this.getUnitImageModalHtml($unitImageArr))
            this.showSlides(1)
        }
    }

    populateMainUnitImages(iconInfo, productId, containerId) {
        const $productImageContainer = $j(containerId)
        const $unitImageContainer = $j(
            $productImageContainer.find(`.unit-pd-img-${productId}`).get(0)
        )
        const $productMainImageContainer = $j(
            $productImageContainer.find(`.main-pd-img-${productId}`).get(0)
        )

        const $unitImageArr = this.getUnitImageArr(iconInfo)

        if ($unitImageArr.length > 0) {
            $unitImageContainer.html(
                this.getUnitImageHtml($unitImageArr, $productMainImageContainer)
            )
            this.showSlides(1)
            $productMainImageContainer.hide()
            $unitImageContainer.show()
        } else {
            $productMainImageContainer.show()
            $unitImageContainer.hide()
        }
    }

    updateProductUnitImages(iconInfo, productId, containerId) {
        this.populateMainUnitImages(iconInfo, productId, containerId)
        this.populateModalUnitImages(iconInfo)
    }

    showUnitSpecialImageModal() {
        if (this.unitImages && this.unitImages.length > 0) {
            this.initUnitImageZoom()
        }
        if ((this.unitImages && this.unitImages.length > 0) || this.condnText) {
            $j(PDPDICT.PDP_SPECIAL_IMG_MODAL.MODAL_ID).fadeIn()
        }
    }

    initUnitImageZoom() {
        const $modalUnitImages = $j('.stock-image.modal-stock-image')
        $modalUnitImages.unbind()

        $modalUnitImages.on('click', function () {
            const $t = $j(this)
            $t.toggleClass('zoomed')
            const currZoom = $t.hasClass('zoomed') ? 2 : 1
            $t.children('.photo').css({
                transform: 'scale(' + currZoom + ')'
            })
        })

        $modalUnitImages.on('mouseout', function () {
            $j(this).children('.photo').css({ transform: 'scale(1)' })
            $j(this).removeClass('zoomed')
        })

        $modalUnitImages.on('mousemove', function (e) {
            $j(this)
                .children('.photo')
                .css({
                    'transform-origin':
                        ((e.pageX - $j(this).offset().left) / $j(this).width()) * 100 +
                        '% ' +
                        ((e.pageY - $j(this).offset().top) / $j(this).height()) * 100 +
                        '%'
                })
        })

        $modalUnitImages.each(function () {
            const w = $j(this).outerWidth(true)
            const height = (w * $j(this).data('height')) / $j(this).data('width')
            const imgSrc = $j(this).find('img').attr('src')

            $j(this).children('.photo').remove()

            $j(this)
                .css({
                    height: height + 'px'
                })
                .append('<div class="photo"></div>')
                .children('.photo')
                .css({
                    'background-image': `url(${imgSrc})`
                })
        })
    }

    populateAndShowUnitImageModal(unitSplImage = null) {
        if (unitSplImage) {
            this.updateProductUnitImages(unitSplImage)
            this.showUnitSpecialImageModal()
        }
    }
}
